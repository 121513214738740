export enum ELanguages {
  UZ = 'uz_lat',
  CRL = 'uz_cryl',
  RU = 'ru',
  KK = 'kk'
}

export enum LanguageEnum {
  UZ = 'uz',
  RU = 'ru',
  CRL = 'crl',
  KK = 'kk',
}

export const LanguageArray: ELanguages[] = [
  ELanguages.UZ,
  ELanguages.RU,
  ELanguages.CRL,
  ELanguages.KK
];

<div [style.width]="width" class="cab-select d-inline-block {{ customClass }}">
  @if (label.length > 1) {
    <ups-input-label [label]="label" [required]="required"></ups-input-label>
  }

  <p-dropdown
    (onChange)="valueChange(value)"
    [(ngModel)]="value"
    [appendTo]="appendToBody ? 'body' : null"
    [dataKey]="dataKey"
    [id]="id"
    [optionValue]="optionValue"
    [options]="options"
    [placeholder]="placeholder | translate"
    [readonly]="readonly"
    [showClear]="true"
    class="w-100"
    optionLabel="name"
    placeholder=""
  >
    <ng-template let-item pTemplate="selectedItem">
      @if (item.color && colorPermission) {
        <div [style]="{ color: item.color }" class="d-flex align-items-center">
          <keen-icon [name]="item.icon" size="medium-4"></keen-icon>
          <span style="padding-left: 2px">{{ getName(item) }}</span>
        </div>
      } @else {
        <span style="padding-left: 2px">{{ getName(item) }}</span>
      }
    </ng-template>
    <ng-template let-ticketTypesOption pTemplate="item">
      @if (ticketTypesOption.color && colorPermission) {
        <div
          [style]="{ color: ticketTypesOption.color }"
          class="d-flex align-items-center"
        >
          <keen-icon [name]="ticketTypesOption.icon" size="medium-4"></keen-icon>
          <span style="padding-left: 2px">{{ getName(ticketTypesOption) }}</span>
        </div>
      } @else {
        <span style="padding-left: 2px">{{ getName(ticketTypesOption) }}</span>
      }
    </ng-template>
  </p-dropdown>

  <!--  @if (showValidationErrors()) {-->
  <!--  <div class="text-danger" [innerHTML]="errorMessagesHtml()"></div>-->
  <!--  }-->

  <div *ngIf="showError" class="error-message label-small-error">
    <span
      *ngIf="showValidationErrors()"
      class="p-error"
    >{{ this.errorMessage() }}</span>
  </div>
</div>

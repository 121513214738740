import {Component, Input, ViewEncapsulation} from '@angular/core';
import {BasicFormInputComponent} from '@shared/components/forms/basic/basic-form-input.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {InputLabelComponent} from '@shared/components/forms/components/input-label/input-label.component';
import {DropdownModule} from 'primeng/dropdown';
import {KeenIconComponent} from '@app/shared/components/keen-icon/keen-icon.component';
import {NgIf} from '@angular/common';
import {LanguageEnum} from '@core/enums/Languages.enum';

@Component({
  selector: 'app-cab-select',
  templateUrl: './cab-select.component.html',
  styleUrls: ['./cab-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    InputLabelComponent,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DropdownModule,
    KeenIconComponent,
    NgIf,
  ],
})
export class CabSelectComponent extends BasicFormInputComponent {
  @Input() options: any;
  @Input() width = '100%';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() onTranslate = true;
  @Input() clearable = true;
  @Input() selectedId: any;
  @Input() dataKey: string = 'id';
  @Input() optionValue: string = 'id';
  @Input() colorPermission: boolean = true;
  @Input() showError: boolean = true;
  @Input() appendToBody: boolean = false;

  ngOnInit() {
    super.ngOnInit();

    if (this.selectedId) {
      this._value = this.selectedId;
    }
    if (this.onTranslate) {
      this.translateOptions();
    }
  }

  public compareWithFunc(a: any, b: string) {
    let res = false;
    if (a[this.bindValue] && b) {
      res = a[this.bindValue] == b;
    }
    return res;
  }

  translateOptions(): void {
    if (this.options) {
      this.options.forEach((option: any) => {
        if (option[this.bindLabel]) {
          option[this.bindLabel] = this._translateService.instant(
            option[this.bindLabel]
          );
        }
      });
    }
  }

  getName(item: any) {
    if (item.nameUz || item.nameRu || item.nameCrl) {
      switch (this._translateService.getDefaultLang()) {
        case LanguageEnum.UZ:
          return item.nameUz;
        case LanguageEnum.CRL:
          return item.nameCrl || item.nameUz;
        case LanguageEnum.RU:
          return item.nameRu || item.nameUz;
      }
    } else {
      switch (this._translateService.getDefaultLang()) {
        case LanguageEnum.UZ:
          return item.name;
        case LanguageEnum.CRL:
          return item.name_crl || item.name;
        case LanguageEnum.RU:
          return item.name_ru || item.name;
        case LanguageEnum.KK:
          return item.name_kk || item.name;
      }
    }
  }
}
